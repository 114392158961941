var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('layout-content',{directives:[{name:"loading",rawName:"v-loading",value:(_vm.loading),expression:"loading"}],attrs:{"header":_vm.$t('automatic.ip_pool.ip_list'),"back-to":{ name: 'IpPoolList' }}},[_c('complex-table',{attrs:{"data":_vm.data,"pagination-config":_vm.paginationConfig,"search-config":_vm.searchConfig,"selects":_vm.selects},on:{"update:selects":function($event){_vm.selects=$event},"search":_vm.search},scopedSlots:_vm._u([{key:"header",fn:function(){return [_c('el-button-group',[_c('el-button',{attrs:{"size":"small"},on:{"click":function($event){return _vm.create()}}},[_vm._v(" "+_vm._s(_vm.$t("commons.button.create"))+" ")]),_c('el-button',{attrs:{"size":"small","disabled":_vm.selects.length===0},on:{"click":function($event){return _vm.del()}}},[_vm._v(" "+_vm._s(_vm.$t("commons.button.delete"))+" ")]),_c('el-button',{attrs:{"size":"small"},on:{"click":function($event){return _vm.sync()}}},[_vm._v(" "+_vm._s(_vm.$t("commons.button.sync"))+" ")])],1)]},proxy:true}])},[_c('el-table-column',{attrs:{"type":"selection","fix":""}}),_c('el-table-column',{attrs:{"sortable":"","label":_vm.$t('automatic.ip_pool.address'),"prop":"address","mix-width":"100"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var row = ref.row;
return [_vm._v(_vm._s(row.address))]}}])}),_c('el-table-column',{attrs:{"sortable":"","prop":"gateway","label":_vm.$t('automatic.ip_pool.gateway'),"mix-width":"100"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var row = ref.row;
return [_vm._v(_vm._s(row.gateway))]}}])}),_c('el-table-column',{attrs:{"sortable":"","prop":"dns1","label":_vm.$t('automatic.ip_pool.dns1'),"mix-width":"100"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var row = ref.row;
return [_vm._v(_vm._s(row.dns1))]}}])}),_c('el-table-column',{attrs:{"sortable":"","prop":"dns2","label":_vm.$t('automatic.ip_pool.dns2'),"mix-width":"100"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var row = ref.row;
return [_vm._v(_vm._s(row.dns2))]}}])}),_c('el-table-column',{attrs:{"sortable":"","label":_vm.$t('automatic.ip_pool.status'),"prop":"status","mix-width":"100"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var row = ref.row;
return [(row.status ==='IP_AVAILABLE')?_c('div',[_c('span',{staticClass:"iconfont iconduihao",staticStyle:{"color":"#32B350"}}),_vm._v(" "+_vm._s(_vm.$t("automatic.ip_pool.ip_available"))+" ")]):_vm._e(),(row.status ==='IP_USED')?_c('div',[_c('span',{staticClass:"iconfont iconin-use",staticStyle:{"color":"#FA5D50"}}),_vm._v(" "+_vm._s(_vm.$t("automatic.ip_pool.ip_used"))+" ")]):_vm._e(),(row.status ==='IP_REACHABLE')?_c('div',[_c('span',{staticClass:"iconfont iconping",staticStyle:{"color":"#FA5D50"}}),_vm._v(" "+_vm._s(_vm.$t("automatic.ip_pool.ip_reachable"))+" ")]):_vm._e(),(row.status ==='IP_LOCK')?_c('div',[_c('span',{staticClass:"iconfont iconlock",staticStyle:{"color":"#FA5D50"}}),_vm._v(" "+_vm._s(_vm.$t("automatic.ip_pool.ip_lock"))+" ")]):_vm._e()]}}])}),_c('el-table-column',{attrs:{"sortable":"","label":_vm.$t('commons.table.create_time'),"prop":"createdAt"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var row = ref.row;
return [_vm._v(_vm._s(_vm._f("datetimeFormat")(row.createdAt)))]}}])}),_c('fu-table-operations',{attrs:{"buttons":_vm.buttons,"label":_vm.$t('commons.table.action')}})],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }